<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">
          Access denied!
        </span>
        <v-icon color="warning">
          {{ icons.mdiAlert }}
        </v-icon>
      </h2>
      <p class="text-sm">
        You are not authorized to access this page.
      </p>
      <v-btn color="info" @click="onRefresh" class="mb-4">
        Refresh
      </v-btn>
      <br>
      <v-btn text color="primary" :to="{ name: 'logout' }" class="mb-4">
        Logout
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'
import { useRouter } from '@/utils/index'

export default {
  setup() {
    const { route, router } = useRouter()

    const onRefresh = () => {
      const { query } = route.value
      const redirectTo = query.redirectTo && typeof query.redirectTo === 'string'
        ? query.redirectTo
        : '/'

      router.push(redirectTo)
    }

    return {
      onRefresh,

      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
